<template>
  <div class="row">
    <div class="col-md-12 px-7">
      <div class="row py-3 px-4" id="filter-card">
        <form @submit.prevent="filterResult" class="w-100">
          <div class="col-lg-12 col-xl-12 px-0" v-if="filters">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-6 col-md-6 col-sm-12 my-2 row px-0 mx-0 d-flex justify-content-start items-center">
                <input-filter
                    :helper-text="$t('general.search')"
                    title="general.search"
                    :placeholder="$t('general.search_filter')"
                    :model.sync="filters.search"
                ></input-filter>
              </div>
              <div class="col-lg-5 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.start_date')"
                      title="general.start_date"
                      :model.sync="filters.start_date"
                      name="start_time">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.end_date')"
                      title="general.end_date"
                      :model.sync="filters.end_date"
                      name="end_date">
                  </date-filter>
                </div>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button @click="filterResult(null)"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="resetFilters"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class=" px-10 py-4">
            <template>
              <PackingTaskCard
                  @click="onClickCard"
                  :task="tasks.item"
                  :type="2"
              ></PackingTaskCard>
            </template>

            <template >
              <div class="w-100 mt-5" v-if="!tasks || !tasks.hasOwnProperty('item') || !tasks.item.length">
                <h3 class="text-center">{{ $t('invoice.no_task') }}</h3>
              </div>
            </template>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import TaskIndexCard from "@/view/pages/samples/layout/task/TaskIndexCard";
import InputFilter from "@/assets/components/filters/InputFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import Paginate from "@/assets/components/dataTable/Pagination";
import moment from "moment";
import {GET_ITEMS, LOADING} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import PackingTaskCard from "./PackingTaskCard";
import {FOLLOWING_PRODUCTION_TASK_START_DATE, FOLLOWING_PRODUCTION_TASK_END_DATE} from "@/core/storage/storage-names"

import {
  FILTER_TASK,
  SET_FILTER_TASK
} from "@/core/services/store/packing-list/packing_list_production.module"
import * as _ from "lodash";
export default {
  name: "InvoiceTasks",
  components: {
    PackingTaskCard,
    DashboardBox,
    TaskIndexCard,
    InputFilter,
    DateFilter,
    Paginate
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER_TASK,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
  },
  data() {
    return {
      tasks: [],
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER_TASK
    }),
    resetFilters() {
      this.setFilter({
        search: null,
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
      })
    },
    filterResult() {
      localStorage.setItem(FOLLOWING_PRODUCTION_TASK_START_DATE, moment(this.filters.start_date));
      localStorage.setItem(FOLLOWING_PRODUCTION_TASK_END_DATE, moment(this.filters.end_date));
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/following-production/packing-task-list',
        acceptPromise: true,
        filters: filters,
        showLoading: true,
      }).then((result) => {
        if (result.status) {
          let items = result.data;
          let results = [];
          items.forEach(item => {
            let inPackage = 0;
            let boxQuantity = 0;
            let amount = 0;
            item.data.forEach(subItem => {
              inPackage += Number(subItem.in_package) || 0;
              boxQuantity += Number(subItem.box_quantity) || 0;
              amount += Number(subItem.amount) | 0;
            })
            results.push({
              created_at: item.created_at,
              order_number: item.orderable.order_number,
              in_package: inPackage,
              box_quantity: boxQuantity,
              amount: amount,
              id: item.id,
            });
          })
          self.tasks = {
            count: 1,
            item: results,
          }
        }
      })
    },
    onClickCard(payload) {
      this.$router.push({name: 'following_production.packing_list.index', params: {packing_id: payload.task.id}})
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('menu.following_production'), route: "following_production.index"},
      {title: this.$t('general.tasks')}
    ]);

    if (!this.filter) {
      this.resetFilters();
    }
    this.filters.end_date = localStorage.hasOwnProperty(FOLLOWING_PRODUCTION_TASK_END_DATE)
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_TASK_END_DATE))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(FOLLOWING_PRODUCTION_TASK_START_DATE)
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_TASK_START_DATE))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

    this.filterResult();
  }
}
</script>

<style scoped>

</style>
